import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g id="Logo" transform="translate(-8.000000, -2.000000)">            
      <g transform="translate(11.000000, 5.000000)">        
        <text x="45%" y="50%" font-size="40" font-weight="bolder" dominant-baseline="middle" text-anchor="middle" fill="#005AF0">J</text>  
        <polygon
          id="Shape"
          stroke="#005AF0"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);



export default IconLogo;
