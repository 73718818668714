module.exports = {
  siteTitle: 'Jay Ng | Software Engineer',
  siteDescription:
    'Jay Ng is a software engineer based in San Francisco, specializing in Full Stack Engineering',
  siteKeywords:
    'Jay Ng, ngchwanlii, software engineer, front-end engineer, back-end engineer, fullstack engineer, mobile engineer, web developer, san francisco,',
  siteUrl: 'https://www.google.com/',
  siteLanguage: 'en_US',

  // TODO: add my own google verification after uploaded my site
  // googleVerification: '',

  name: 'Jay Ng',
  location: 'San Francisco, CA',
  email: 'ngchwanlii@gmail.com',
  github: 'https://github.com/ngchwanlii/',  
  socialMedia: [
    {
      name: 'Github',
      url: 'https://github.com/ngchwanlii/',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/ngchwanlii/',      
    },        
    {
      name: 'Twitter',
      url: 'https://twitter.com/ngchwanlii',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '#about',
    },
    {
      name: 'Skill',
      url: '#jobs',
    },
    {
      name: 'Project',
      url: '#projects',
    },
    {
      name: 'Contact',
      url: '#contact',
    },
  ],

  twitterHandle: '@ngchwanlii',
  // TODO: resume this after adding my site
  // googleAnalyticsID: 'UA-xxxxxxxxx-x',

  navHeight: 100,


  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
